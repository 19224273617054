import React from "react";
import cn from "classnames";
import styles from "./Trade.module.sass";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { getDigitsAfterDecimal, getPostiveNegativeNum, handleImageError } from "../../../../components/helper";
import TradeSkeleton from "../../../../components/Skeleton/TradeSkeleton";

const Trade = ({
  loading,
  search,
  currentItems,
  currentPage,
  paginate,
  totalPages,
  itemsPerPage,
  toggleSortOrder,
  handleSearch,
  sortBy,
  sortOrder
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.trade}>
      <form className={styles.form}>
        <input
          value={search}
          className={styles.input}
          type="text"
          onChange={handleSearch}
          name="search"
          placeholder="Search coin"
          required
        />
        <button onClick={(e) => { e.preventDefault(); }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>

      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>#</div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => { toggleSortOrder("name") }}>
              Name {sortBy === 'name' && (sortOrder === 'asc' ? '↑' : '↓')}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => { toggleSortOrder("price") }}>
              Price {sortBy === 'price' && (sortOrder === 'asc' ? '↑' : '↓')}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => { toggleSortOrder("dayChange") }}>
              24h % {sortBy === 'dayChange' && (sortOrder === 'asc' ? '↑' : '↓')}
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn("sorting")}
              onClick={() => { toggleSortOrder("weekChange") }}
            >
              7d % {sortBy === 'weekChange' && (sortOrder === 'asc' ? '↑' : '↓')}
            </div>
          </div>
          <div className={styles.col}>
            Marketcap
            <Icon name="coin" size="20" />
          </div>
          <div className={styles.col}>
            Volume (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>Action</div>
        </div>

        {loading ? <TradeSkeleton rowCount={30} colCount={8} />
          :
          currentItems?.map((i, ind) => {
            const isClickable = i?.cap && i?.cap !== '0' && i?.cap !== null && i?.cap !== "";
            return (
              <Link className={styles.row} state={i} key={ind} to={isClickable ? `/coins/${i?.name?.toLowerCase()}` : "#"}>
                <div className={styles.col}>
                  <div className={styles.line}>{(currentPage - 1) * itemsPerPage + ind + 1}</div>
                </div>
                <div className={styles.col}>
                  <div className={styles.item}>
                    <div className={styles.icon}>
                      <img src={i.icon} onError={handleImageError} alt="Coin" />
                    </div>
                    <div className={styles.details}>
                      <span className={styles.subtitle}>{i.name}</span>
                      <span className={styles.currency}>{i.symbol}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Price</div>
                  {getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.dayChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.weekChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Marketcap</div>
                  {i.cap}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Volume (24h)</div>
                  {i.volume}
                </div>
                <div className={styles.col}>
                  <div className={cn("button-small", styles.button)} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/sign-in");
                  }}>
                    Login
                  </div>
                </div>
              </Link>
            )
          })}
      </div>
      {
        currentItems?.length > 0 &&
        <div className={styles.pagination}>
          <button
            className={cn(styles.paginationButton, { [styles.disabled]: currentPage === 1 })}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages).keys()].map(pageNumber => (
            <button
              key={pageNumber}
              className={cn(styles.paginationButton, { [styles.active]: currentPage === pageNumber + 1 })}
              onClick={() => paginate(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button
            className={cn(styles.paginationButton, { [styles.disabled]: currentPage === totalPages })}
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      }
    </div>
  );
};

export default Trade;
