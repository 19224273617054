import React from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Item from "./Item";
import LightWeightChart from "../../../components/LightWeightChart";
import { getDigitsAfterDecimal } from "../../../components/helper";

const Details = ({
  state,
  items
}) => {

  // console.log("state", state);

  const isValid = (value) => {
    return value !== null && value !== undefined && value !== '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={cn("h5")}>{`${state?.name}(${state?.symbol})`} Price</div>
        <div className={styles.price}>${state?.currentMarketPrice}</div>
        <div className={styles.historicalPrice}>
          {isValid(state?.cap) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Market Cap</div>
              <div className={styles.value}>${state?.cap}</div>
            </div>
          )}

          {isValid(state?.fully_diluted_market_cap) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Fully Diluted Valuation</div>
              <div className={styles.value}>${state?.fully_diluted_market_cap.toLocaleString()}</div>
            </div>
          )}

          {isValid(state?.market_cap_dominance) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Market Dominance</div>
              <div className={styles.value}>{getDigitsAfterDecimal(state?.market_cap_dominance, 2)}%</div>
            </div>
          )}

          {/* {isValid(state?.volume_change_24h) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>24 Hour Trading Vol</div>
              <div className={styles.value}>${state?.volume_change_24h}</div>
            </div>
          )} */}

          {isValid(state?.circulating_supply) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Circulating Supply</div>
              <div className={styles.value}>{state?.circulating_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
            </div>
          )}

          {isValid(state?.max_supply) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Max Supply</div>
              <div className={styles.value}>{state?.max_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
            </div>
          )}

          {isValid(state?.total_supply) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>Total Supply</div>
              <div className={styles.value}>{state?.total_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
            </div>
          )}
        </div>
        {
          (state?.official_urls || state?.white_paper_urls || state?.mica_paper_urls) && (
            <div className={styles.historicalPrice}>
              <div className={styles.information}>
                <div className={styles.header}>Info</div>
                <div className={styles.buttonContainer}>
                  {state?.official_urls && (
                    <a href={state.official_urls} target="_blank" rel="noopener noreferrer">
                      <button className={styles.button}>
                        <span>Website</span>
                      </button>
                    </a>
                  )}
                  {state?.white_paper_urls && (
                    <a href={state.white_paper_urls} target="_blank" rel="noopener noreferrer">
                      <button className={styles.button}>
                        <span>Whitepaper</span>
                      </button>
                    </a>
                  )}
                  {state?.mica_paper_urls && (
                    <a href={state.mica_paper_urls} target="_blank" rel="noopener noreferrer">
                      <button className={styles.button}>
                        <span>MiCA Whitepaper</span>
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )
        }

        {/* <div className={styles.historicalPrice}>
          <div className={styles.information}>BTC Historical Price</div>
          <div className={styles.priceItem}>
            <div className={styles.key}>24h Range</div>
            <div className={styles.value}>${state?.cap}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>7d Range</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>All-Time High</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>All-Time Low</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
        </div> */}
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.chartContainer}>
          <LightWeightChart
            state={state}
          />
        </div>
        <div className={styles.timeIntervalContainer}>
          <table className={styles.timeIntervalTable}>
            <thead>
              <tr className={styles.tableHeader}>
                <th className={styles.headerCell}>1h</th>
                <th className={styles.headerCell}>24h</th>
                <th className={styles.headerCell}>7d</th>
                <th className={styles.headerCell}>30d</th>
                <th className={styles.headerCell}>60d</th>
                <th className={styles.headerCell}>90d</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>
                  {state?.percent_change_1h > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_1h, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_1h, 2)}%</span>
                  }
                </td>
                <td className={styles.tableCell}>
                  {state?.dayChange > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.dayChange, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.dayChange, 2)}%</span>
                  }
                </td>
                <td className={styles.tableCell}>
                  {state?.weekChange > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.weekChange, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.weekChange, 2)}%</span>
                  }
                </td>
                <td className={styles.tableCell}>
                  {state?.percent_change_30d > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_30d, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_30d, 2)}%</span>
                  }
                </td>
                <td className={styles.tableCell}>
                  {state?.percent_change_60d > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_60d, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_60d, 2)}%</span>
                  }
                </td>
                <td className={styles.tableCell}>
                  {state?.percent_change_90d > 0 ?
                    <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_90d, 2)}%</span>
                    :
                    <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_90d, 2)}%</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className={cn("h4", styles.questions)}>People Also Ask: Other Questions About Bitcoin</div>
        <div className={styles.about}>
          {items.map((x, index) => (
            <Item
              className={styles.item}
              item={x}
              index={index}
              key={index}
            />
          ))}
        </div> */}
      </div>
    </div >
  );
};

export default Details;
