import React, { useEffect, useMemo, useRef, useState } from "react";
import Main from "./Main";
import Details from "./Details";
// import { socket } from "../../socket";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { socketDataReceived } from "../../actions/markets";
import LoaderScreen from "../../components/LoaderScreen";

const Market = () => {
  const [loading, setLoading] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredMarkets, setFilteredMarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(process.env.REACT_APP_MARKET_PAGE_PAGINATION_COUNT);
  const { socketMarketData } = useSelector((state) => { return state.markets });
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [recentMarkets, setRecentMarkets] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [topVolume, setTopVolume] = useState([]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_all_markets_web";
    socket.connect();
    const marketData = "all_markets";
    socket.on("connect", () => {
      socket.emit("marketData", marketData);
    })

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("marketData", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socketMarketData?.length > 0) {
      handleCurrencySelection(selectedCurrency);
    }
  }, [socketMarketData]);

  const handleCurrencySelection = (currency) => {
    if (selectedCurrency !== currency) {
      setSelectedCategory("All");
      setCurrentPage(1);
    }

    setSelectedCurrency(currency);

    const selectedCurrencyData = socketMarketData?.find(item => item.currency?.toLowerCase() === currency?.toLowerCase());

    if (selectedCurrencyData) {
      const updatedCategories = [
        { category: "All", markets: selectedCurrencyData.markets },
        ...(Array.isArray(selectedCurrencyData.category) ? selectedCurrencyData.category : [])
      ];
      setCategories(updatedCategories);

      const filteredData = selectedCategory === "All"
        ? selectedCurrencyData.markets
        : selectedCurrencyData.category?.find(cat => cat.category === selectedCategory)?.markets;

      if (!filteredData && selectedCategory !== "All") {
        setSelectedCategory("All");
        setFilteredMarkets(selectedCurrencyData.markets);
      } else {
        setFilteredMarkets(filteredData);
      }

      const sortedMarkets = [...selectedCurrencyData.markets]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const recentMarkets = sortedMarkets.slice(0, 3);
      setRecentMarkets(recentMarkets);

      const topGainers = [...selectedCurrencyData.markets]
        .filter((coin) => coin.dayChange > 0)
        .sort((a, b) => b.dayChange - a.dayChange)
        .slice(0, 3);
      setTopGainers(topGainers);

      const topLosers = [...selectedCurrencyData.markets]
        .filter((coin) => coin.dayChange < 0)
        .sort((a, b) => a.dayChange - b.dayChange)
        .slice(0, 3);
      setTopLosers(topLosers);

      const topVolume = [...selectedCurrencyData.markets]
        .sort((a, b) => {
          const volumeA = parseFloat(a.volume.replace(/,/g, ''));
          const volumeB = parseFloat(b.volume.replace(/,/g, ''));
          return volumeB - volumeA;
        })
        .slice(0, 3);
      setTopVolume(topVolume);
    }
    else {
      setCategories([]);
      setFilteredMarkets([]);
      setTopGainers([]);
      setTopLosers([]);
      setTopVolume([]);
    }
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);

    const selectedCurrencyData = socketMarketData.find(item => item.currency === selectedCurrency);
    if (selectedCurrencyData) {
      if (category?.toLowerCase() === "all") {
        setFilteredMarkets(selectedCurrencyData.markets);
      }
      else {
        const selectedCategoryData = selectedCurrencyData.category.find(cat => cat.category === category);
        if (selectedCategoryData) {
          setFilteredMarkets(selectedCategoryData.markets);
        }
      }
    }
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredBySearch = filteredMarkets?.filter(market =>
    market?.name?.toLowerCase()?.includes(search?.toLowerCase()) || market?.slug?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const sortedMarkets = useMemo(() => {
    if (isFirstRender.current) {
      return filteredBySearch;
    }

    return [...filteredBySearch].sort((a, b) => {
      let compareA, compareB;

      if (sortBy === "name") {
        compareA = a.name.toLowerCase();
        compareB = b.name.toLowerCase();
      }
      else if (sortBy === "price") {
        compareA = a.currentMarketPrice;
        compareB = b.currentMarketPrice;
      }
      else if (sortBy === 'dayChange') {
        compareA = a.dayChange;
        compareB = b.dayChange;
      }
      else if (sortBy === 'weekChange') {
        compareA = a.weekChange;
        compareB = b.weekChange;
      }

      if (sortOrder === "asc") {
        return compareA > compareB ? 1 : -1;
      }
      else {
        return compareA < compareB ? 1 : -1;
      }
    });
  }, [filteredBySearch, sortBy, sortOrder]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedMarkets?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math?.ceil(sortedMarkets?.length / itemsPerPage);

  const toggleSortOrder = (column) => {
    if (sortBy === column) {
      setSortOrder(prevSortOrder => prevSortOrder === "asc" ? "desc" : "asc");
    }
    else {
      setSortBy(column);
      setSortOrder("asc");
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  };

  return (
    <>
      {socketMarketData <= 0 && <LoaderScreen />}
      <Main />
      <Details
        search={search}
        loading={loading}
        socketMarketData={socketMarketData}
        selectedCurrency={selectedCurrency}
        categories={categories}
        selectedCategory={selectedCategory}
        handleCategorySelection={handleCategorySelection}
        handleCurrencySelection={handleCurrencySelection}
        currentItems={currentItems}
        currentPage={currentPage}
        paginate={paginate}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        toggleSortOrder={toggleSortOrder}
        handleSearch={handleSearch}
        sortBy={sortBy}
        sortOrder={sortOrder}
        recentMarkets={recentMarkets}
        topGainers={topGainers}
        topLosers={topLosers}
        topVolume={topVolume}
      />
    </>
  );
};

export default Market;
