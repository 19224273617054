import React, { useRef, useEffect } from "react";
import styles from "./index.module.sass";
import { widget } from "../../charting_library/charting_library.esm";
import Datafeed from "./dataFeed";

import {
	DISABLED_FEATURES,
	ENABLED_FEATURES,
	TIME_FRAMES,
} from "./chartConfig";
import { _unsubs } from "./stream";

function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, " "));
}

const TVChartContainer = ({ symbol, currentMarketDetails, theme }) => {
	const chartContainerRef = useRef(null);
	const tvWidgetRef = useRef(null);

	const getStoredInterval = () => {
		const savedInterval = localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution");
		return savedInterval || process.env.REACT_APP_DEFAULT_INTERVAL_VALUE;
	};

	useEffect(() => {
		// Initialize the widget only if it doesn't exist
		setTimeout(() => {
			if (!tvWidgetRef.current) {
				const widgetOptions = {
					symbol: `BITDENEX:${symbol?.toUpperCase()}`,
					datafeed: Datafeed,
					container: chartContainerRef.current,
					theme: theme,
					library_path: "/charting_library/",
					locale: getLanguageFromURL() || "en",
					fillBackground: true,
					interval: getStoredInterval(),
					fullscreen: false,
					autosize: true,
					time_frames: TIME_FRAMES,
					enabled_features: ENABLED_FEATURES,
					disabled_features: DISABLED_FEATURES,
					toolbar: false,
				};

				tvWidgetRef.current = new widget(widgetOptions);

				tvWidgetRef.current.onChartReady(() => {
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 10 }, { "plot.color.0": "#9b59b6", "showLabelsOnPriceScale": false });
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 30 }, { "plot.color.0": "#d35400", "showLabelsOnPriceScale": false });
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 99 }, { "plot.color.0": "#00cec9", "showLabelsOnPriceScale": false });

					//Remove "Volume" study if it exists
					const studies = tvWidgetRef.current.activeChart().getAllStudies();
					for (let i = 0; i < studies.length; i++) {
						const study = studies[i];
						if (study.name === 'Volume') {
							tvWidgetRef.current.activeChart().removeEntity(study.id);
							break;
						}
					}
				});
			}
		}, 1000);
		return () => {
			if (tvWidgetRef.current !== null) {
				tvWidgetRef.current.remove();
				tvWidgetRef.current = null;
			}
		};
	}, []);

	useEffect(() => {
		// Apply theme changes dynamically
		if (tvWidgetRef.current) {
			tvWidgetRef.current.applyOverrides({
				"paneProperties.background": theme === "dark" ? "#131722" : "#FFFFFF",
			});
		}
	}, [theme]);

	useEffect(() => {
		if (tvWidgetRef.current) {
			let previousSymbol = tvWidgetRef.current.activeChart().symbol();
			let previousResolution = tvWidgetRef.current.activeChart().resolution();
			localStorage.setItem("tradingview.chart.lastUsedTimeBasedResolution", previousResolution);
			previousSymbol = previousSymbol.toUpperCase()
			var unsubscribeUid = previousSymbol + '_#_' + previousResolution
			Datafeed.unsubscribeBars(unsubscribeUid)
			tvWidgetRef.current.activeChart().setSymbol(`BITDENEX:${symbol?.toUpperCase()}`, () => { });

			tvWidgetRef.current.activeChart().setResolution(localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution"), () => { });
		}
	}, [symbol]);

	return (
		<>
			<header className={styles.VersionHeader}></header>
			<div ref={chartContainerRef} className={styles.TVChartContainer} />
		</>
	);
};

export default TVChartContainer;