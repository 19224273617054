import React, { useEffect, useRef, useState } from "react";
import Main from "./Main";
import Learn from "./Learn";
import Trend from "./Trend";
import Popular from "../../components/Popular";
import Download from "./Download";
import News from "../../components/News";
import Steps from "./Steps";
import MaintenaceNotice from "../../components/MaintenaceNotice";
// import { socket } from "../../socket";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { socketDataReceived } from "../../actions/markets";

const Home = () => {
  const scrollToRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { socketMarketData } = useSelector((state) => state.markets);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_all_markets_web";
    socket.connect();
    const marketData = "all_markets";
    socket.on("connect", () => {
      socket.emit("marketData", marketData);
    })

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("marketData", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE_MODE == 0 && (
        <MaintenaceNotice />
      )}
      <Main
        scrollToRef={scrollToRef}
        loading={socketMarketData?.length <= 0 && loading}
        socketMarketData={socketMarketData}
      />
      <Trend
        socketMarketData={socketMarketData}
        loading={socketMarketData?.length <= 0 && loading}
      />
      <Learn scrollToRef={scrollToRef} />
      <Popular classSection="section-bg section-mb0" />
      <Download />
      <News classSection="section-bg" />
      <Steps />
    </>
  );
};



export default Home;
