import React from 'react'
import styles from "./Card.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, getPostiveNegativeNum, handleImageError } from "../../../../../components/helper";

const Card = ({ coins, title }) => {
    return (
        <div className={styles.coinCard}>
            <a className={styles.coinCardLink}>
                <div className={styles.coinTitle}>{title}</div>
            </a>
            <div className={styles.coinList}>
                {
                    coins?.length > 0 ?
                        coins.map((coin, coinIndex) => (
                            <div
                                className={styles.coinItem}
                                key={coinIndex}
                            >
                                <div className={styles.coinInfo}>

                                    <img className={styles.coinLogo} src={coin?.icon} onError={handleImageError} alt="Coin" />
                                    <div className={styles.coinSymbol}>{coin.symbol}</div>
                                </div>
                                <div className={styles.coinPrice}>{getDigitsAfterDecimal(coin.currentMarketPrice, coin.priceDecimalPrecision)}</div>
                                <div className={styles.coinChange}>
                                    {getPostiveNegativeNum(coin.dayChange) ?
                                        <div className={styles.positive}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                        :
                                        <div className={styles.negative}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                    }
                                </div>
                            </div>
                        ))
                        :
                        (
                            <div className={styles.noData}>No data available</div>
                        )
                }
            </div>
        </div>
    )
}

export default Card
