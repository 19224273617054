import React from "react";
import styles from "./Panel.module.sass";
import Card from "./Card";

const Panel = ({
  recentMarkets,
  topGainers,
  topLosers,
  topVolume,
  selectedCurrency
}) => {

  return (
    <div className={styles.coinMarket}>
      <div className={styles.flexContainer}>
        <Card coins={recentMarkets} title={`Newly Added Coins(${selectedCurrency})`} />
        <Card coins={topGainers} title={`Top Gainers(${selectedCurrency})`} />
        <Card coins={topLosers} title={`Top Losers(${selectedCurrency})`} />
        <Card coins={topVolume} title={`Top Volume(${selectedCurrency})`} />
      </div>
    </div >
  );
};
export default Panel;