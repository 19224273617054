import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Market from "./screens/Market";
import LearnCrypto from "./screens/LearnCrypto";
import LearnCryptoDetails from "./screens/LearnCryptoDetails";
import AmlPolicy from "./screens/AmlPolicy";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import LocationRestriction from "./screens/LocationRestriction";
import TermsAndConditionsForReferralProgram from "./screens/TermsAndConditionsForReferralProgram";
import BuyAndSellBitcoin from "./screens/BuyAndSellBitcoin";
import BuyAndSellTron from "./screens/BuyAndSellTron";
import BuyAndSellEthereum from "./screens/BuyAndSellEthereum";
import BuyAndSellLitecoin from "./screens/BuyAndSellLitecoin";
import BuyAndSellRipple from "./screens/BuyAndSellRipple";
import BuyAndSellSteller from "./screens/BuyAndSellStellar";
import Fees from "./screens/Fees";
import LegalCenter from "./screens/LegalCenter";
import HelpCenter from "./screens/HelpCenter";
import KycPolicy from "./screens/KycPolicy";
import Videos from "./screens/Videos"
import BitdenexPay from './screens/BitdenexPay'
import BitdenexPartners from './screens/BitdenexPartners'
import BitdenexApp from "./screens/BitdenexApp";
import CoinListing from './screens/CoinListing'
import PageNotFound from './screens/PageNotFound'
import ReferralProgram from './screens/ReferralProgram'
import AboutCoin from './screens/AboutCoin'
import BitdenexToken from './screens/BitdenexToken';
import Staking from './screens/Staking';
import BuyAndSellBitdenex from "./screens/BuyAndSellBitdenex";
import BuyAndSellCardano from "./screens/BuyAndSellCardano";
import BuyAndSellSolana from "./screens/BuyAndSellSolana";
import CoinDetail from "./screens/CoinDetail";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (localStorage.getItem('signature') && localStorage.getItem('signature') !== ""
      || localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token') !== ""
      || localStorage.getItem('switch_exchange') && localStorage.getItem('switch_exchange') !== "") {

      deleteAllCookies();
      localStorage.removeItem('signature')
      localStorage.removeItem('jwt_token')
      localStorage.removeItem('switch_exchange')
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Page>
              <Home />
            </Page>}
        />
        <Route
          exact
          path="/about/:slug"
          element={
            <Page>
              <AboutCoin />
            </Page>
          }
        />
        <Route
          exact
          path="/market"
          element={
            <Page>
              <Market />
            </Page>
          }
        />
        <Route
          exact
          path="/learn-crypto"
          element={
            <Page>
              <LearnCrypto />
            </Page>
          }
        />
        <Route
          exact
          path="/video-tutorials"
          element={
            <Page>
              <Videos />
            </Page>
          }
        />
        <Route
          exact
          path="/learn-crypto-details/:slug"
          element={
            <Page>
              <LearnCryptoDetails />
            </Page>
          }
        />
        <Route
          exact
          path="/sign-in"
          element={
            <Page headerHide footerHide>
              <SignIn />
            </Page>
          }
        />
        <Route
          exact
          path="/sign-up"
          element={
            <Page headerHide footerHide>
              <SignUp />
            </Page>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <Page headerHide footerHide>
              <ForgotPassword />
            </Page>
          }
        />
        <Route
          exact
          path="/terms-and-conditions"
          element={
            <Page>
              <TermsAndConditions />
            </Page>
          }
        />
        <Route
          exact
          path="/coin-listing"
          element={
            <Page>
              <CoinListing />
            </Page>
          }
        />
        <Route
          exact
          path="/referral-and-affiliate"
          element={
            <Page>
              <ReferralProgram />
            </Page>
          }
        />
        <Route
          exact
          path="/terms-and-conditions-referral-program"
          element={
            <Page>
              <TermsAndConditionsForReferralProgram />
            </Page>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <Page>
              <PrivacyPolicy />
            </Page>
          }
        />
        <Route
          exact
          path="/kyc-policy"
          element={
            <Page>
              <KycPolicy />
            </Page>
          }
        />
        <Route
          exact
          path="/aml-policy"
          element={
            <Page>
              <AmlPolicy />
            </Page>
          }
        />
        <Route
          exact
          path="/location-restriction"
          element={
            <Page>
              <LocationRestriction />
            </Page>
          }
        />
        <Route
          exact
          path="/terms-and-conditions-for-referral-program"
          element={
            <Page>
              <TermsAndConditionsForReferralProgram />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-bitcoin"
          element={
            <Page>
              <BuyAndSellBitcoin />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-bitdenex-token"
          element={
            <Page>
              <BuyAndSellBitdenex />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-cardano"
          element={
            <Page>
              <BuyAndSellCardano />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-solana"
          element={
            <Page>
              <BuyAndSellSolana />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-tron"
          element={
            <Page>
              <BuyAndSellTron />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-ethereum"
          element={
            <Page>
              <BuyAndSellEthereum />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-litecoin"
          element={
            <Page>
              <BuyAndSellLitecoin />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-ripple"
          element={
            <Page>
              <BuyAndSellRipple />
            </Page>
          }
        />
        <Route
          exact
          path="/buy-steller"
          element={
            <Page>
              <BuyAndSellSteller />
            </Page>
          }
        />
        <Route
          exact
          path="/fees"
          element={
            <Page>
              <Fees />
            </Page>
          }
        />
        <Route
          exact
          path="/help-center"
          element={
            <Page>
              <HelpCenter />
            </Page>
          }
        />
        <Route
          exact
          path="/legal-center"
          element={
            <Page>
              <LegalCenter />
            </Page>
          }
        />
        <Route
          exact
          path="/bitdenex-pay"
          element={
            <Page>
              <BitdenexPay />
            </Page>
          }
        />
        <Route
          exact
          path="/bitdenex-partners"
          element={
            <Page>
              <BitdenexPartners />
            </Page>
          }
        />
        <Route
          exact
          path="/learn-crypto"
          element={
            <Page>
              <LearnCrypto />
            </Page>
          }
        />
        <Route
          exact
          path="/bitdenex-app"
          element={
            <Page>
              <BitdenexApp />
            </Page>
          }
        />
        <Route
          exact
          path="/bitdenex-token"
          element={
            <Page>
              <BitdenexToken />
            </Page>
          }
        />
        <Route
          exact
          path="/staking"
          element={
            <Page>
              <Staking />
            </Page>
          }
        />
        <Route
          exact
          path="/coins/:slug"
          element={
            <Page>
              <CoinDetail />
            </Page>
          }
        />
        <Route
          exact
          path="*"
          element={
            <PageNotFound />
          }
        />
      </Routes>
    </Router>
  );
}

function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


export default App;
