import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
} from 'chart.js';
import styles from "./LightWeightChart.module.sass";
import { getDigitsAfterDecimal } from "../../components/helper";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
);

const LightWeightChart = ({
    state
}) => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('7D');

    useEffect(() => {
        const socket = new WebSocket(process.env.REACT_APP_API_URL_CHARTS);
        const endtime = Math.floor(Date.now() / 1000);

        socket.onopen = () => {
            socket.send(
                JSON.stringify({
                    action: 'subscribe',
                    channel: state?.slug?.toLowerCase().replace("-", "_"),
                    resolution: '1D',
                    endTime: endtime
                })
            );
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message && message.message.data) {
                const newData = message.message.data.map((item) => ({
                    time: item.time * 1000,
                    open: item.open,
                    high: item.high,
                    low: item.low,
                    close: item.close,
                }));

                setData(newData);
                filterData('7D', newData);
                socket.close();
            }
        };

        socket.onerror = (error) => {
        };

        socket.onclose = () => {
        };

        return () => {
            socket.close();
        };
    }, []);

    const filterData = (period, data) => {
        let filtered;
        const now = Date.now();
        const oneDay = 24 * 60 * 60 * 1000;
        const sevenDays = 7 * oneDay;
        const fourteenDays = 14 * oneDay;
        const twentyOneDays = 21 * oneDay;
        const twentyEightDays = 28 * oneDay;

        switch (period) {
            case '1D':
                filtered = data.filter(item => (now - item.time) <= oneDay);
                break;
            case '7D':
                filtered = data.filter(item => (now - item.time) <= sevenDays);
                break;
            case '14D':
                filtered = data.filter(item => (now - item.time) <= fourteenDays);
                break;
            case '21D':
                filtered = data.filter(item => (now - item.time) <= twentyOneDays);
                break;
            case '28D':
                filtered = data.filter(item => (now - item.time) <= twentyEightDays);
                break;
            default:
                filtered = data.filter(item => (now - item.time) <= sevenDays);
        }
        setFilteredData(filtered);
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `Close Price: ${getDigitsAfterDecimal(context.raw, state?.priceDecimalPrecision)}`;
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
            y: {
                position: 'right',
                beginAtZero: false,
                suggestedMin: Math.min(...filteredData.map(item => item.low)),
                suggestedMax: Math.max(...filteredData.map(item => item.high)),
            },
        },
    };

    const chartData = {
        labels: filteredData.map(item => {
            const date = new Date(item.time);
            return date.toLocaleDateString('en-US', {
                // year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }),

        datasets: [
            {
                label: 'Open Price',
                data: filteredData.map(item => item.open),
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                fill: 'origin',
                backgroundColor: 'rgba(75,192,192,0.2)',
                pointRadius: 5,
            },
        ],
    };

    return (
        <>
            <div className={styles.buttonContainer}>
                <button
                    className={`${styles.button} ${selectedPeriod === '7D' ? styles.selected : ''}`}
                    onClick={() => {
                        setSelectedPeriod('7D');
                        filterData('7D', data);
                    }}
                >
                    1w
                </button>
                <button
                    className={`${styles.button} ${selectedPeriod === '14D' ? styles.selected : ''}`}
                    onClick={() => {
                        setSelectedPeriod('14D');
                        filterData('14D', data);
                    }}
                >
                    2w
                </button>
                <button
                    className={`${styles.button} ${selectedPeriod === '21D' ? styles.selected : ''}`}
                    onClick={() => {
                        setSelectedPeriod('21D');
                        filterData('21D', data);
                    }}
                >
                    3w
                </button>
                <button
                    className={`${styles.button} ${selectedPeriod === '28D' ? styles.selected : ''}`}
                    onClick={() => {
                        setSelectedPeriod('28D');
                        filterData('28D', data);
                    }}
                >
                    4w
                </button>
            </div>
            <Line data={chartData} options={chartOptions} />
        </>
    );
};

export default LightWeightChart;