import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Cards from "./Cards";
import Trade from "./Trade";

const Details = ({
  loading,
  search,
  selectedCurrency,
  selectedCategory,
  categories,
  socketMarketData,
  handleCategorySelection,
  handleCurrencySelection,
  currentItems,
  currentPage,
  paginate,
  totalPages,
  itemsPerPage,
  toggleSortOrder,
  handleSearch,
  sortBy,
  sortOrder,
  recentMarkets,
  topGainers,
  topLosers,
  topVolume,
}) => {

  return (
    <div className={cn("section", styles.details)}>
      <div className={styles.container}>
        <Cards
          recentMarkets={recentMarkets}
          topGainers={topGainers}
          topLosers={topLosers}
          topVolume={topVolume}
          selectedCurrency={selectedCurrency}
        />
        <Panel
          selectedCurrency={selectedCurrency}
          selectedCategory={selectedCategory}
          categories={categories}
          socketMarketData={socketMarketData}
          handleCategorySelection={handleCategorySelection}
          handleCurrencySelection={handleCurrencySelection}
        />
        <Trade
          loading={loading}
          search={search}
          currentItems={currentItems}
          currentPage={currentPage}
          paginate={paginate}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          toggleSortOrder={toggleSortOrder}
          handleSearch={handleSearch}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </div>
    </div>
  );
};

export default Details;
