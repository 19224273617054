import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Dropdown from "../../../../components/Dropdown";

const Panel = ({
  selectedCurrency,
  selectedCategory,
  categories,
  socketMarketData,
  handleCurrencySelection,
  handleCategorySelection
}) => {

  return (
    <div className={styles.panelContainer}>
      <div className={styles.footerContainer}>
        <div className={styles.navContainer}>
          {categories?.map((category, index) => (
            <button
              className={cn(styles.navLink, {
                [styles.activeNavLink]: category.category === selectedCategory,
              })}
              onClick={() => { handleCategorySelection(category.category) }}
              key={index}
            >
              {category.category}
            </button>
          ))}
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            value={selectedCurrency}
            setValue={handleCurrencySelection}
            options={socketMarketData.map(x => x.currency)}
          />
        </div>
      </div>
    </div>
  );
};
export default Panel;