import React from "react";
import Details from "./Details";
import { useLocation } from "react-router";

const Market = () => {
  const { state } = useLocation();

  const items = [
    {
      title: " How long does it take before deposits are visible? ",
      content:
        'The speed of your deposit depends on your payment method. Bitcoin: With Bitcoins deposits we are dependent on the Blockchain network. Usually within 30-60 minutes, after 3 network confirmations your Bitcoins will be credited or debited from your account. Sometimes it can take longer because the network is too busy. ',
    },
    {
      title: " Wallets changes, is that possible? ",
      content:
        'NO. Unfortunately, you can not change your Bitcoin landfill address, Ethereum landfill address or other landfill addresses. You can, however, create new pouring addresses. In addition, you can continue to use your old addresses. You may create as many addresses as you think you need. ',
    },

    {
      title: "What is Bitdenex? ",
      content:
        'Bitdenex is a European Cryptocurrency Exchange, also known as a trading platform, based in the Netherlands. At Bitdenex you can buy and sell coins such as Bitcoin, Ethereum, Litecoin and other coins quickly, safely and easily. In the exchange you can easily exchange coins with other available coins. ',
    },
    {
      title: "Why Bitdenex? ",
      content:
        'Buy and sell cryptocurrency or digital coins can be difficult, especially if you have not previously bought a coin. It can sometimes take a long time to investigate how you can actually buy a coin. Unfortunately, not all exchanges offer all coins. You can view per cryptocurrency you want to buy at which exchange that cryptocurrency is available. With Bitdenex Exchange you can buy and sell coins easily and quickly. You can choose from the payment methods such as (very popular) iDeal. Once the payment is successful, you will immediately receive the purchased coins in your Bitdenex account. ',
    },
    {
      title: " What do you offer? ",
      content:
        'Bitdenex provide opportunity to interested people to find interesting projects in cryptocurrency. On the other hand, individuals may find the right trading platform to fulfill their investment dreams. Bitdenex don’t give advice to anyone. We only create the opportunity for the people to buy and sell cryptocurrency easily. ',
    },
    // {
    //   title: "Does Bitdenex require a licence from the authorities ? ",
    //   content:
    //     'NO. Bitdenex is a trading platform for cryptocurrencies. In this capacity Bitdenex does not require a license from both Dutch Central Bank (DNB) and Authorities Financiele Markets (AFM). Bitdenex don’t offer financial products to our customers where we need financial data from individual customers. Bitdenex only offers the possibility to buy and sell a cryptocurrency at your own risk. When a license is required, Bitdenex will apply to the authorities to get a license. ',
    // },
    // {
    //   title: "Does it matter where I am from? ",
    //   content:
    //     'Yes. Bitdenex offer our service in Europe. As a non-European citizen, you can’t get access to the trading platform. ',
    // },

    // {
    //   title: "Do I need skills to use the service? ",
    //   content:
    //     'No. Bitdenex trading platform is made user-friendly so it is simple and easy to trade. Moreover, Bitdenex team will provide you any support you need! ',
    // },

    // {
    //   title: "Why should I join Bitdenex? ",
    //   content:
    //     'By joining Bitdenex you will have a chance to contribute to the fair market and decentralized eco-system.',
    // },

    // {
    //   title: " Why do I have to create an Bitdenex account? ",
    //   content:
    //     'To prevent fraud and scam, it is necessary to create an account. Your account must be verified so that we can be sure that you are the right person. Through these identification and verification obligations we try to prevent fraud and scam as much as possible. You also need to validate a bank account once in your Bitdenex account. After all identification and verification obligations you can start trading digital coins. ',
    // },
    // {
    //   title: " Why do I have to create an Bitdenex account? ",
    //   content:
    //     'To prevent fraud and scam, it is necessary to create an account. Your account must be verified so that we can be sure that you are the right person. Through these identification and verification obligations we try to prevent fraud and scam as much as possible. You also need to validate a bank account once in your Bitdenex account. After all identification and verification obligations you can start trading digital coins. ',
    // },
  ];

  return (
    <>
      <Details
        state={state}
        items={items}
      />
    </>
  );
};

export default Market;
