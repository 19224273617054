import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import Faq from "./Faq";
import MarginDetails from "./MarginDetails";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";

const Fees = () => {
  const [loading, setLoading] = useState(false);
  const [leverageData, setLeverageData] = useState([]);

  // useEffect(() => {
  //   getLeverageDataHandler();
  // }, []);

  // const getLeverageDataHandler = async () => {
  //   setLoading(true);
  //   try {
  //     const getLeverageDataPayload = await requestHandler("marginDetails", "post");
  //     setLoading(false);
  //     setLeverageData(getLeverageDataPayload?.data?.data);
  //   }
  //   catch (e) {
  //     setLoading(false);
  //   };
  // };

  return (
    <>
      {loading && <LoaderScreen />}
      <Main />
      <Details />
      {/* <MarginDetails
        leverageData={leverageData}
      /> */}
      <Faq />
    </>
  );
};

export default Fees;
